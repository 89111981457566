/*
 * Create a content cycler, that cycles through images dynamically
 * Required structure:
 * <div class="js-content-cycler" data-image-url="{{ image_url }}" [data-cycle-intervall="6000"]>
 *  <img src="{{ initial_image_src }}" class="" />
 *  <img src="{{ initial_image_src }}" class="opacity-0" />
 * </div>
 *
 * Or include the partials/_content_cycler.html file:
 * {% routablepageurl page "header_slider_images" as image_url %}
 * {% include 'partials/_content_cycler.html' with image_url=image_url image=image only %}
 */
const ContentCycler = (el: HTMLElement) => {
  const images = Array.from(el.querySelectorAll("img"));
  const imageUrl = el.dataset.imageUrl;
  const cycleIntervall = parseInt(el.dataset.cycleIntervall) || 6000;

  if (!images.length || !imageUrl) {
    throw Error("ContentCycler: No images or image url found");
  }

  let index = 1;
  let selected: HTMLImageElement;
  let next: HTMLImageElement;

  const cycleImages = () => {
    selected = images.find((e) => !e.classList.contains("opacity-0"));
    next = images.find((e) => e.classList.contains("opacity-0"));

    fetch(`${imageUrl}?index=${index}`)
      .then((response) => response.json())
      .then((data: { index: number; image: string }) => {
        index = data.index;
        next.src = data.image;
        next.onload = () => {
          selected.classList.add("opacity-0");
          next.classList.remove("opacity-0");
          setTimeout(() => {
            cycleImages();
          }, cycleIntervall);
        };
      });
  };

  setTimeout(cycleImages, cycleIntervall);
};

export default ContentCycler;
