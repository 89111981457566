import "htmx.org"; // todo: separate htmx into a "vendor" bundle
import "slick-carousel";

import $ from "jquery";

import ContentCycler from "./ContentCycler";

$(document).ready(function () {
  $(".ch24-benefit-arrow-icon").click(function () {
    $(this).find(".ch24-benefits-text").toggle();
    $(this).find(".ch24-benefit-arrow-icon").toggleClass("rotate-180");
  });
});

// Mobile menu open and close
$(document).ready(function () {
  const mainHeader = document.querySelector(".main-header");
  const mainNavigationToggle = document.querySelector(".main-header--toggle");
  const sidebar = document.querySelector(".main-header--navigation");

  function hideMobileNavigation() {
    mainHeader.classList.remove("mainmenu-is-visible");
    sidebar.style.top = "";
  }

  mainNavigationToggle.addEventListener("click", function (event) {
    mainHeader.classList.toggle("mainmenu-is-visible");
    event.stopPropagation();
  });

  document.addEventListener("click", hideMobileNavigation);
  window.addEventListener("resize", hideMobileNavigation);
});

// focus on input when clicking on wrapping div
$(document).ready(function () {
  $(".ch24-input").click(function () {
    $(this).find('input[type="text"]').focus();
  });
});

$(document).ready(function () {
  $(".js-toggleDropdown").click(function () {
    $(this).find(".arrow-icon").toggleClass("rotate-180");
    $(this).parent().find(".js-toggleDropdown--text").toggleClass("hidden");
  });
});

// toggle between multiple stories
$(document).ready(() => {
  if (!document.querySelector(".js-multiStory")) {
    // don't do anything if there is no slider to initialize
    return;
  }

  const buttons = document.querySelectorAll(
    ".js-multiStory button[data-target]"
  );
  buttons.forEach((btn) => {
    btn.addEventListener("click", () => {
      const { target } = btn.dataset;
      const stories = document.querySelectorAll(
        ".js-multiStory div[data-story-id]"
      );

      // highlight button
      btn.classList.add("border-brand", "text-brand");
      buttons.forEach((el) => {
        if (el !== btn) {
          el.classList.remove("border-brand", "text-brand");
        }
      });

      // display story
      stories.forEach((el) => {
        if (el.dataset.storyId === target) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      });
    });
  });

  const slider = document.querySelector(".js-multiStory .buttons-container");
  const nSlides = slider.children.length;
  const childrenTotalWidth = Array.from(slider.children).reduce((acc, el) => {
    return acc + el.clientWidth;
  }, 0);
  if (slider.clientWidth < childrenTotalWidth) {
    const prevButton = document.querySelector(".js-multiStory .prev-arrow");
    const nextButton = document.querySelector(".js-multiStory .next-arrow");
    nextButton.classList.remove("hidden");

    $(slider).slick({
      infinite: false,
      variableWidth: true,
      slidesToShow: 1,
      nextArrow: nextButton,
      prevArrow: prevButton,
    });
    $(slider).on("beforeChange", (event, slick, currentSlide, nextSlide) => {
      // hide prev button if first slide is active
      if (nextSlide === 0) {
        prevButton.classList.add("hidden");
        prevButton.classList.remove("hidden");
      }
      // hide next button if no more slides left
      if (nextSlide < nSlides - 1) {
        nextButton.classList.remove("hidden");
        nextButton.classList.add("hidden");
      }
    });
  }
});

window.addEventListener("load", () => {
  document.querySelectorAll(".js-content-cycler").forEach((el) => {
    ContentCycler(el);
  });
});
